<template>
  <div class="pageSize main" style="position: relative;">

    <Head bgColor="#C8EBED"></Head>
    <div class="matrix">
      <div class="matrix_item1 matrix_item_flex">
        <div class="matrix_item_left">
          <div class="matrix_item_left_brn">
            前置因素
          </div>
        </div>
        <div class="matrix_item_right"
          v-if="objData.familySicks && objData.familySicks[0]?.balanceDetail && objData.familySicks[0]?.balanceDetail.length > 0">
          <div style="display: flex;flex-wrap: wrap;">
            <div class="matrix_item_right_text" v-for="(item, index) in objData.familySicks[0]?.balanceDetail"
              :key="index">
              {{ item.subUnBalanceTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="matrix_item2 matrix_item_flex">
        <div class="matrix_item_left">
          <div class="matrix_item_left_brn">
            诱发因素
          </div>
        </div>
        <div class="matrix_item_right" v-if="objData.inductionFactorList?.length > 0">
          <div style="display: flex;flex-wrap: wrap;">
            <div class="matrix_item_right_text" v-for="(item, index) in objData.inductionFactorList" :key="index">
              <template v-if="index != 0">、</template>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="matrix_item3 matrix_item_flex">
        <div class="matrix_item_left">
          <div class="matrix_item_left_brn">
            生理失衡
          </div>
        </div>
        <div class="matrix_item_right" v-if="objData.unBlanceList && objData.unBlanceList?.length > 0">
          <div style="display: flex;flex-wrap: wrap;">
            <div class="matrix_item_right_text" v-for="(item, index) in objData.unBlanceList" :key="index">
              <template v-if="index != 0">、</template>
              {{ item.unBalanceTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="matrix_item4 matrix_item_flex">
        <div class="matrix_item_left">
          <div class="matrix_item_left_brn">
            媒介因素
          </div>
        </div>
        <div class="matrix_item_right" v-if="objData.sickAgeList && objData.sickAgeList?.length > 0">
          <div class="picture">
            <div class="picture_info">
              <div class="picture_item" v-for="num in objData.userInfo.userAge" :key="num">
                <div class="picture_item_sick" v-for="(item, index) in objData.sickAgeList">
                  <template v-if="item.sickAge == num">
                    <div class="sick_List">
                      <div class="sickList_item" v-for="item1 in item.sickList">
                        {{ item1.sickName }}
                      </div>
                    </div>
                    <!-- {{ item.sickAge }} -->
                  </template>
                </div>
                <div class="picture_item_num" v-if="num % 5 == 0">
                  {{ num }}
                </div>
              </div>
            </div>
          </div>
          <div class="start">0</div>
        </div>
      </div>
      <div class="matrix_item5 matrix_item_flex">
        <div class="matrix_item_left">
          <div class="matrix_item_left_brn">
            现有问题
          </div>
        </div>
        <div class="matrix_item_right" v-if="objData.nowSicks && objData.nowSicks?.length > 0">
          <div style="display: flex;flex-wrap: wrap;">
            <div class="matrix_item_right_text" v-for="(item, index) in objData.nowSicks" :key="index">
              <!-- <template v-if="index<30"> -->
              <template v-if="index != 0">、</template>
              {{ item.sickName }}
              <!-- </template> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showFactorCollectionText" v-for="(item, index) in objData.showFactorCollectionText" :key="index">
      <span class="showFactorCollectionText_title">{{ item.title }}</span> {{ item.text }}
    </div>
<div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
5 of {{ pageAllNum }}
</div>

  </div>
</template>
<script>
import Head from './head.vue'
export default {
  data () {
    return {
      text: ''
    }
  },
  components: {
    Head
  },
  props: ['objData', 'pageAllNum'],
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        // let age = newValueA.userInfo.userAge
        const sickAgeList = newValueA.sickAgeList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  .matrix {
    width: 960px;
    height: 1000px;
    margin: 20px auto;
    margin-top: 120px;
    border: 1px solid #C9CACA;
    font-size: 30px;

    .matrix_item1 {
      width: 960px;
      height: 140px;
      background-color: #C9CACA;
    }

    .matrix_item2 {
      width: 960px;
      height: 140px;
      background-color: #DCDDDD;
    }

    .matrix_item3 {
      width: 960px;
      height: 240px;
      background-color: #EFEFEF;
    }

    .matrix_item4 {
      position: relative;
      width: 960px;
      height: 200px;
      background-color: #F9F9F9;

      .picture {
        width: 780px;
        height: 30px;
        margin-top: 165px;
        font-size: 20px;
        background-color: #2baea9;

        .picture_info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 30px;

          .picture_item {
            position: relative;
            flex: 1;

            .picture_item_sick {
              position: absolute;
              top: -160px;

              .sick_List {
                width: 200px;

                .sickList_item {
                  position: relative;
                  left: 20px;
                  font-size: 80%;
                  color: #474F57;
                  width: 70px;
                }
              }
            }

            .picture_item_num {
              position: relative;
              top: 3px;
            }
          }
        }
      }

      .start {
        position: absolute;
        font-size: 20px;
        bottom: 3px;
        left: 190px;
      }

      .end {
        position: absolute;
        font-size: 20px;
        bottom: 2px;
        left: 1050px;
      }
    }

    .matrix_item5 {
      width: 960px;
      height: 280px;
      background-color: #F9F9F9;
    }

    .matrix_item_flex {
      display: flex;
      overflow: hidden;
    }

    .matrix_item_left {
      display: flex;
      align-items: center;
      color: #fff;
      padding-left: 20px;

      .matrix_item_left_brn {
        width: 140px;
        height: 40px;
        border-radius: 25px;
        text-align: center;
        line-height: 40px;
        background-color: #2baea9;
        font-size: 24px;
      }
    }

    .matrix_item_right {
      display: flex;
      font-size: 60%;
      align-items: center;
      line-height: 28px;
      padding-left: 20px;
      color: #474F57;
    }
  }

  .showFactorCollectionText {
    width: 960px;
    margin: 10px auto;
    background-color: #EFEFEF;
    padding: 10px 20px;
    font-size: 180%;
    color: #474F57;

    .showFactorCollectionText_title {
      display: inline-block;
      padding: 0px 10px;
      border: 1px solid #2baea9;
      border-radius: 6px;
      font-weight: 600;
    }
  }
}

.footerNum {
  z-index: 9999999999;
  position: absolute;
  bottom: 60px;
  left: 92%;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
</style>
